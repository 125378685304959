import { FILTERS_EQUAL } from "@kraftheinz/common";
import numeral from "numeral";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit GTC Pasteuriser", "has-deactivate-button": false, "custom-url": _vm.customUrl, "custom-list-url": _vm.customListUrl } }, [_c("select-input", { key: "canSizeId", attrs: { "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "label": "Can Size", "lazy": "", "placeholder": "Select Can Size", "reference": "sterilization.common.can-sizes", "source": "id", "source-label": "name", "span": 12 } }), _c("text-input", { key: "minInitTemp", attrs: { "data-index": "minInitTemp", "sorter": true, "label": "Minimum Initial Temp (\xB0C)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "minPastTemp", attrs: { "data-index": "minPastTemp", "sorter": true, "label": "Minimum IPasteuriser Temp (\xB0C)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "minPastSpeed", attrs: { "data-index": "minPastSpeed", "sorter": true, "label": "Minimum IPasteuriser Speed (mins)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "minWaterLevel", attrs: { "data-index": "minWaterLevel", "sorter": true, "label": "Water Level (%min)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "maxWaterLevel", attrs: { "data-index": "maxWaterLevel", "sorter": true, "label": "Water Level (%max)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "pastTempSetPoint", attrs: { "data-index": "pastTempSetPoint", "sorter": true, "label": "Temp Set Point (\xB0C)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "pastWaterLevelSetPoint", attrs: { "data-index": "pastWaterLevelSetPoint", "sorter": true, "label": "Water Level Set Point (%)", "span": 12, "parse-value": _vm.parseDecimal } }), _c("text-input", { key: "pastSpeedSetPoint", attrs: { "data-index": "pastSpeedSetPoint", "sorter": true, "label": "Speed Set Point (mins)", "span": 12, "parse-value": _vm.parseDecimal } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditGTCPasteuriserModal",
  data() {
    const customUrl = `${"#{VUE_APP_API_URL}#"}/sterilization/data-entries/${this.$route.params.id}/pasteurisers`;
    return {
      customUrl,
      customListUrl: customUrl,
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ]
    };
  },
  methods: {
    parseDecimal(value) {
      return numeral(value).format("0.0");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditGTCPasteuriserModal = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries.pasteurisers", "api-url": _vm.apiUrl, "edit-route-id-name": "gtcId", "redirect-route": _vm.redirectRoute } }, [_c("edit-gtc-pasteuriser-modal")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    "edit-gtc-pasteuriser-modal": EditGTCPasteuriserModal
  },
  props: {
    dataEntryId: {
      type: [Number, String],
      default: 0
    }
  },
  provide() {
    return {
      dataEntryId: this.dataEntryId
    };
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      redirectRoute: `/sterilization-specifications/data-entries/${this.$route.params.id}`,
      name: `sterilization.data-entries.pasteurisers`
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
